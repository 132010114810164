<template>
    <div>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :filename="FileName"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-content-width="100%"
            :htmlToPdfOptions="pdfOption"
            ref="html2Pdf">
            
            <section slot="pdf-content">
                <div class="pdf-default">
                    <label class="form-label" style="font-size: 10px;">{{DatetimeNow}}</label>
                    <label class="form-label" style="float: right; font-size: 10px;">{{SPNumber}}</label>
                    <br>
                    <div class="row p-2">
                        <div class="col-3 bg">
                            <img class="item" :src="require('../../../public/img/avatars/Logo.jpg')" width="100%"/>
                        </div>
                        <div class="col-9 p-0">
                            <br>
                            <h2><b>PT. BOJONG WESTPLAS</b></h2>
                            <label><b>Modern Pipe Manufacturer</b></label>
                        </div>
                    </div>

                    <div style="text-align: center;">
                        <h4><b>Persetujuan Pembelian</b></h4>
                        <label>{{SPNumber}}</label>
                    </div>

                    <div class="row p-2">
                        <div class="col-6 px-3">
                            <div class="row">
                                <div class="col-sm-label">
                                    <label class="form-label">Kantor</label>
                                </div>
                                <div class="col-sm-dot">
                                    <label class="form-label">:</label>
                                </div>
                                <div class="col-sm-content">
                                    <label class="form-label">Jl. Raya Pluit Selatan No.12 Jakarta Utara 14450</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-label">
                                    <label class="form-label">Telepon</label>
                                </div>
                                <div class="col-sm-dot">
                                    <label class="form-label">:</label>
                                </div>
                                <div class="col-sm-content">
                                    <label class="form-label">6696152 - 6696128, 6602841</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-label">
                                    <label class="form-label">Fax</label>
                                </div>
                                <div class="col-sm-dot">
                                    <label class="form-label">:</label>
                                </div>
                                <div class="col-sm-content">
                                    <label class="form-label">6605107</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-label">
                                    <label class="form-label">Pabrik</label>
                                </div>
                                <div class="col-sm-dot">
                                    <label class="form-label">:</label>
                                </div>
                                <div class="col-sm-content">
                                    <label class="form-label">Jl. Sera kp. Cibadak RT. 012 RW. 005 - Bojong Cikupa <br> Tangerang Banten</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-label">
                                    <label class="form-label">Nomor PO</label>
                                </div>
                                <div class="col-sm-dot">
                                    <label class="form-label">:</label>
                                </div>
                                <div class="col-sm-content">
                                    <label class="form-label">{{PONumber}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-label">
                                    <label class="form-label">Tanggal</label>
                                </div>
                                <div class="col-sm-dot">
                                    <label class="form-label">:</label>
                                </div>
                                <div class="col-sm-content">
                                    <label class="form-label">{{PODate}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 px-3">
                            <div class="row">
                                <div class="col-sm-label">
                                    <label class="form-label">Kepada</label>
                                </div>
                                <div class="col-sm-dot">
                                    <label class="form-label">:</label>
                                </div>
                                <div class="col-sm-content">
                                <label class="form-label"><span>{{CustomerName}} <br> {{CustomerAddress}} <br> {{CustomerCity}} - {{CustomerProvince}} <br> Indonesia</span></label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-label">
                                    <label class="form-label">Alamat Kirim</label>
                                </div>
                                <div class="col-sm-dot">
                                    <label class="form-label">:</label>
                                </div>
                                <div class="col-sm-content">
                                    <label class="form-label"><span>{{ProjectName}} <br> {{AddressSend}}</span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="p-2">
                        <label class="form-label"><span>Dengan Hormat, <br> Kami menyetujui atas pesanan Saudara yang telah kami terima dengan kondisi sebagai berikut:</span></label>
                        <table border="1" class="mytable mytable-head">
                            <thead>
                                <tr>
                                    <td class="px-3" width="10%" style="text-align: center;"><label>Jumlah</label></td>
                                    <td class="px-3" width="10%" style="text-align: center;"><label>Satuan</label></td>
                                    <td class="px-3" width="40%" style="text-align: center;"><label>Nama Barang</label></td>
                                    <td class="px-3" width="20%" style="text-align: center;"><label>Harga</label></td>
                                    <td class="px-3" width="20%" style="text-align: center;"><label>Total</label></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(Item, index) in Items" :key="index">
                                    <td class="px-2"><label style="float: right;">{{Item['qty']}}</label></td>
                                    <td class="px-2"><label>{{Item['uom']}}</label></td>
                                    <td class="px-2"><label>{{Item['name']}}</label></td>
                                    <td class="px-2"><label style="float: right;">Rp. {{Item['net_string']}}</label></td>
                                    <td class="px-2"><label style="float: right;">Rp. {{Item['total_net_string']}}</label></td>
                                </tr>
                            </tbody>
                        </table>
                        <table border="1" class="mytable mytable-body">
                            <tbody>
                                <tr>
                                    <td class="px-2" width="80%"><label style="float: right;">Jumlah harga jual</label></td>
                                    <td class="px-2" width="20%"><label style="float: right;">Rp. {{TotalPO}}</label></td>
                                </tr>
                                <tr>
                                    <td class="px-2" width="80%"><label style="float: right;">PPN = {{PPNTax}}% x Jumlah harga jual</label></td>
                                    <td class="px-2" width="20%"><label style="float: right;">Rp. {{TotalPOPPN}}</label></td>
                                </tr>
                                <tr>
                                    <td class="px-2" width="80%"><label style="float: right;">Jumlah yang harus di bayar</label></td>
                                    <td class="px-2" width="20%"><label style="float: right;">Rp. {{NetPO}}</label></td>
                                </tr>
                            </tbody>
                        </table>
                        <table border="1" class="mytable mytable-footer">
                            <tbody>
                                <tr>
                                    <td class="px-2" width="100%"><label>#Terbilang: {{POTotalString}} Rupiah</label></td>
                                </tr>
                            </tbody>
                        </table>
                        <br>

                        <div class="row">
                            <div class="col-3">
                                <label class="form-label">Syarat Pembayaran</label>
                            </div>
                            <div class="col-sm-dot">
                                <label class="form-label">:</label>
                            </div>
                            <div class="col-sm-content">
                                <label class="form-label">{{PaymentTerm}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label class="form-label">Catatan</label>
                            </div>
                            <div class="col-sm-dot">
                                <label class="form-label">:</label>
                            </div>
                            <div class="col-sm-content">
                                <label class="form-label">{{Note}}</label>
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <div class="col-9">
                            </div>
                            <div class="col-3">
                                <label class="form-label"><span>Tangerang, {{PODate}}<br>Hormat Kami,</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-9">
                                <label class="form-label"><span>Disetujui Oleh Pembeli,</span></label>
                            </div>
                            <div class="col-3">
                                <label class="form-label"><span>PT Bojong Westplas</span></label>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-3">
                                <img id="Attachment" :src="SrcImg" width="100%"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-9">
                                <label class="form-label">Nama Jelas dan Cap Perusahaan</label>
                            </div>
                            <div class="col-3">
                                <label class="form-label"></label>
                            </div>
                        </div>
                        <br>
                        <label class="form-label">Print by {{Name}} on {{DatetimeNow}}</label>
                    </div>
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import { apiBaseUrlDownload } from '../../infrastructure/constant/connection';
import moment from 'moment';
import poServices from '../../Areas/Transaction/PurchaseOrder/Script/PurchaseOrderScript';
import angkaTerbilang from '@develoka/angka-terbilang-js';
import { globalfunc } from '../../shared/GlobalFunction';

export default {
    name: 'PurchaseOrderPDF',
    components: {
        'vue-html2pdf': VueHtml2pdf
    },
    data(){
        return{
            FileName: "PurchaseOrder_" + moment(new Date()).format("YYYY-MM-DD-HHmmss"),
            DatetimeNow: '',
            SPNumber: '',
            CustomerName: '',
            CustomerAddress: '',
            CustomerCity: '',
            CustomerProvince: '',
            PONumber: '',
            PODate: '',
            Items: [],
            ProjectName: '',
            AddressSend: '',
            PaymentTerm: '',
            Note: '',
            Name: this.$cookies.get('Name'),
            TotalPO: 0,
            TotalPOPPN: 0,
            NetPO: 0,
            POTotalString: '',
            PPNTax: '',
            SrcImg: '',
            pdfOption: {
                margin: [5,5],
                html2canvas: {
                    scale: 4,
                    useCORS: true
                },
                jsPDF: {
                    unit: 'mm',
                    format: 'a4',
                    orientation: 'portrait'
                },
                pagebreak: {
                    avoid: ['tr', 'css', 'legacy']
                },
            }
        }
    },
    methods: {
        async generatePDF(id) {
            var data = await poServices.getPurchaseOrderQuery(id);
            
            this.DatetimeNow = moment(new Date()).format("YYYY-MM-DD hh:mm A");
            this.SPNumber = data.sp_number;
            this.CustomerName = data.customer_name;
            this.CustomerAddress = data.customer.metadata.profile.address;
            this.CustomerCity = data.customer.metadata.profile.city;
            this.CustomerProvince = data.customer.metadata.profile.province;

            this.PONumber = data.purchase_order_number;
            this.PODate = moment(data.purchase_order_date).format("DD MMM YYYY");
            this.ProjectName = data.project_name;
            this.AddressSend = data.address;

            var duedateDays = data.term_of_payment;

            //table
            var poData = poServices.itemPOPDF(data.purchase_order_detail, data.booking_order);
            this.Items = poData;

            var payment = '';
            if(data.payment_method == 'Cash'){
                payment = 'Cash (Tunai)';
                this.PaymentTerm = payment + ' dari tanggal invoice.';
            }
            else if(data.payment_method == 'Credit'){
                payment = 'Credit (Kredit)';
                this.PaymentTerm = payment + ' ' + duedateDays + ' hari dari tanggal invoice.';
            }

            this.PPNTax = data.tax_percentage;

            var array = this.Items;
            var totalPPNTax = 0;
            var totalGrossDisc = 0;
            var grandTotal = 0;

            if(array.length > 0){
                array.forEach(e => {
                    totalGrossDisc = totalGrossDisc + (e.total_net);
                    totalPPNTax = totalPPNTax + (e.net * (this.PPNTax / 100)) * e.qty;
                });
            }

            grandTotal = totalGrossDisc + Math.round(totalPPNTax);

            this.TotalPO = kendo.toString(totalGrossDisc, "n0");
            this.TotalPOPPN = kendo.toString(totalPPNTax, "n0");
            this.NetPO = kendo.toString(grandTotal, "n0");
            this.POTotalString = globalfunc.titleCase(angkaTerbilang(grandTotal));

            this.Note = data.notes;

            this.SrcImg = apiBaseUrlDownload + data.customer.attachment1;

            this.$refs.html2Pdf.generatePdf();
        },
    }
}
</script>

<style scoped>
.col-sm-dot, .col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 30%;
    }
    .col-sm-dot {
        width: 5%;
    }
    .col-sm-content {
        width: 60%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.mytable {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
.mytable-head {
    border: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-head td {
    border: 1px solid black;
}
.mytable-body {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-body td {
    border: 1px solid black;
    border-top: 0;
}
.mytable-footer {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
.mytable-footer td {
    border: 1px solid black;
    border-top: 0;
}
.bg {
    background-color: transparent;
}
.item {
    mix-blend-mode: multiply;
}
</style>